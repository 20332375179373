.template-item-buttons {
    float:right;
    padding-right: 20px;
}

.folder-sidebar li{
    display: block;
}

.folder-sidebar .form-control{
    width: auto;
    display: inline-block;
    margin-right: 10px;
}

.folder-sidebar .ant-menu-item div {
    display: inline-block;
}

.folder-sidebar .ant-menu {
    padding-left: 0px;
}

.folder-sidebar .ant-menu-item{
    padding-left: 0px !important;
}
.folder-sidebar .ant-menu-item .anticon, .accordion .anticon{
    padding: 5px;
    display: inline-flex;
}

.folder-sidebar.card-body, .template-list.card-body {
    min-height: 600px;
}

.accordion .menu-item {
    display: block !important;
    margin-left: 20px;
    background-color: #ddd;
}

.accordion .menu-item:first-child {
    margin-top: 5px;
}

.accordion .collapse.show {
    background-color: white;
}

.accordion .card-body {
    padding: 0px;
}

.accordion .card {
    background-color: #fff;
    border: none;
    margin-top: 5px;
}

.accordion .card-header {
    background-color: white;
    color: #007bff;
    padding: .25rem 1.25rem;
    padding-left: 5px;
}

.bm-menu .bm-item:focus{
    outline: none !important;
}

.bm-menu {
    background: white;
    border: 1px solid #ddd;
}

.bm-item-list {
    color: #007bff;
}

.menu-item {
    background-color: #fff;
}

.menu-item span time {
	color:black;
	font-weight: bold;
}

.template-list.article-list-item {
    border-bottom: 1px solid #ccc;
}

.article-list-item .form-check-inline{
    float:left;
    margin-top: 5px;
}

.body-email-folder-list {
    width: 500px;
    height: 500px;
}

.article-list-item input[type='checkbox'] {
    float: left;
    margin-top:5px;
    margin-right: 5px;
}

.modal-body p {
    margin-bottom: 0px;
} 

.modal-md {
    max-width: 80% !important;
    height: 90%;
}

.bootstrap-demo .dropdown-trigger {
    border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
}

.bootstrap-demo .root {
    padding: 0px;
    margin: 0px;
}

.modal-preview-template .modal-content {
    min-height: 100%;
    height:auto !important;
}

.react-dropdown-tree-select .dropdown {
    font-size: 14px;
}

.folder-sidebar .icon-action-template {
    float:right;
}

.preview-draft {
    padding-left: 10px;
}